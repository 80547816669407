@import "sass-lib";

@media (min-width: 0px) and (max-width: 990px) {
	h1{
		&.h1s2{font-size: 60px;}
	}
	h2{font-size: 32px;}
	h3{
		&.h3s2{font-size: 35px;}
	}
	h5{font-size: 18px;}

	.wrap-xs, .wrap-sm2{width: 100%;}
	.wrapper, .wrap-md, .wrap-md2{
		width: 100%; @include boxSizing(border-box); padding: 0 40px;
		.wrap-md{padding: 0;}
	}

	header{
		.navigation{
			position: absolute;top: 100%; left: 0; right: 0; background: $white; padding: 129px 0 60px; margin-top: -99px; height: 100vh; border-top: 1px solid #dddbdb; @include boxSizing(border-box); display: none; overflow: auto; z-index: -1;
			> li{
				display: block; float: none; text-align: center;
				> a{
					font-size: 24px; padding: 20px;
					&:before{display: none;}
				}
				.subnav{display: none;}
				&.active{
					> a{font-weight: 700; color: $blue;}
				}
			}
		}
		.nav-lang{
			margin-right: 20px;
			.btn-lang{
				padding-left: 20px; padding-right: 20px;
				&:before{display: none;}
			}
		}
		.btn-burgmenu{
			display: block; position: relative; float: right; margin-left: 25px; margin-top: 5px; width: 28px; height: 28px;
			span{
				display: block; width: 28px; height: 4px; background: $blue; margin-bottom: 5px; clear: both; float: right; @include transition(0.2s all ease-out);
				&:nth-child(1), &:nth-child(3){width: 18px;}
			}

			&.close{
				span{
					&:nth-child(1){@include transform(rotate(45deg)); width: 100%; position: absolute; top: 50%;}
					&:nth-child(2){opacity: 0;}
					&:nth-child(3){@include transform(rotate(-45deg)); width: 100%; position: absolute; top: 50%;}
				}
			}
		}
		.box-sosmed{
			position: relative; bottom: auto; left: auto; right: 0; margin-top: 40px;display: block;text-align: center;
			@include afterboth;
			a{
				margin-right: 25px;
				&:last-child{margin-right: 0;}
			}
		}
		.wrapper{border-bottom: 1px solid #dddbdb; background: $white;}
	}
	
	.box-slidehome{
		.box-filter{width: auto; left: 40px; right: 40px; bottom: -320px;}
	}
	.slidehome{
		.list{
			figure{
				height: 500px;
				img{object-fit: cover; height: 100%; width: 100%;}
			}
			.desc{
				width: auto; left: 40px; right: 40px;
				h5, h1, div{max-width: 370px;}
			}
		}
	}
	.box-filter{
		.left, .center{width: 100%; margin: 0;}
		.left{
			margin-bottom: 30px;
			h3{max-height: none;}
		}
		.center{margin-bottom: 10px;}
		.right{
			position: relative; float: right; width: 100%; background: transparent; text-align: right;
			input[type=submit]{
				background-color: $blue; background-position: 92% 50%; @include borderRadius(10px); width: auto; height: 50px; font-size: 16px; color: $white; font-weight: 600; text-align: left; padding: 10px 50px 10px 20px;
				&:hover{background-position: 95% 50%;}
			}
		}
	}
	.sec-service{
		.top-service{padding-top: 380px; padding-bottom: 50px;}
		.in-service{width: 100%; padding: 0 20px; @include boxSizing(border-box);}
	}
	.servicelist{
		.ls-service{
			h5{font-size: 16px; margin-bottom: 5px;}
			p{font-size: 13px; line-height: 18px; height: 36px;}
			.icoservice{width: 60px; height: 70px;bottom: 50px;}
			.desc{padding-left: 20px; padding-right: 20px; padding-bottom: 20px;}
		}
	}
	.slidecapsfasility{
		.list{
			h3{font-size: 25px; margin-bottom: 15px;}
			p{overflow: hidden; max-height: 110px;}
		}
	}
	.sec-about{
		padding-top: 65px; padding-bottom: 90px; min-height: 460px;
		.desc-abouthome{
			h2{line-height: 40px;}
		}
	}
	.sec-news{
		.lg-news{width: 100%; margin-bottom: 70px;}
		.sm-news{
			width: auto; margin-left: -15px; margin-right: -15px; float: none; display: block;
			.ls-news{
				padding-left: 15px; padding-right: 15px;
				&:before{left: 15px; right: 15px;}
				.desc{padding-bottom: 20px;}
			}
		}
	}
	.slidenews{
		.slick-dots{
			li{
				margin-right: 20px;
				button{width: 11px; height: 11px;}
			}
		}
	}

	.contentwp{
		figure{margin-bottom: 40px;}
	}

	.sliderewards{
		.list{
			&.prev1{@include transform(scale(1.5));}
			&.next1{@include transform(scale(1.5));}
			&.slick-current{@include transform(scale(2));}
		}
	    &.minduo{
	        .list{
	        	margin: 30px 0;
	            &.slick-current{@include transform(scale(1.05));z-index: 2;}
	            &.prev1{@include transform(scale(1));z-index: -1;}
	            &.prev2{@include transform(scale(1));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1));z-index: -1;}
	            &.next2{@include transform(scale(1));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	}
	.slidecapsrewards{width: auto;}
	.boxslidemilestone{padding: 0 80px;}
	.servicerow{
		.ls-servicerow{
			margin-bottom: 100px;
			.desc{
				padding-top: 20px;
				p{line-height: 24px;}
			}
		}
	}
	.box-slide-detail{
		.leftcapsbeauty{
			width: 100%; border: none; padding: 0; margin-bottom: 50px;
			.ttlrescaps{display: block; text-transform: uppercase; font-weight: 700; font-size: 13px; color: #999999;width: 360px; margin: 0 auto 10px;}
		}
		.slideimgdetail{width: 100%;}
	}
	.box-capsbeauty{
		width: 360px; margin: 0 auto; position: relative; max-width: 100%;
	    .btncapsbeauty{
	    	display: block; background: $white; @include borderRadius(8px); padding: 15px 20px; position: relative; border: 1px solid #b7b7b7; cursor: pointer;
	    	&:before{content: ""; position: absolute; top: 0; right: 0; bottom: 0; background: url('../images/material/arr-select.png') no-repeat right center; width: 30px;}
	    }
		.capsbeauty{
			position: absolute; top: 100%; left: 5px; right: 5px; z-index: 10; background: $white; padding: 20px; @include borderRadius(0 0 8px 8px);border: 1px solid #b7b7b7; border-top: none; display: none; max-height: 185px; overflow: auto;
		}
	}
	.capsbeauty{
		.list{
			margin-bottom: 10px;
			&.active{
				padding-left: 0; font-size: 16px;
				&:before{display: none;}
			}
		}
	}
	.box-spesialis{
		padding-top: 70px;
		.right{padding-top: 5px;}
	}
	.slidecapsspesialis{
		&:before{bottom: -54px;}
		h3{font-size: 22px; margin-bottom: 15px;}
		p{max-height: 110px;}
		.list{padding-bottom: 20px;}
		.slick-prev{bottom: -40px;}
		.slick-next{bottom: -40px;}
	}
	.btn-tab{
		margin-bottom: 50px;
		.lsbtn-tab{
			margin-right: 50px;
		}
	}
	.classlist{
		margin-bottom: 0; margin-left: -20px; margin-right: -20px;
		.ls-classlist{
			width: 50%; padding-left: 20px; padding-right: 20px;
			figure{
				/*&:before{display: none;}*/
			}
			.desc{width: 200%; padding-left: 20px; padding-right: 20px;}
			&:nth-child(3n+1){clear: inherit;}
			&:nth-child(2n+1){
				clear: both;
				.desc{left: 0%;}
			}
			&:nth-child(2n){
				.desc{left: -100%;}
			}


	        &:hover{
	            figure{
	                &:before{opacity: 0; visibility: hidden;}
	            }
	            .desc{opacity: 0; visibility: hidden;}
	        }
	        &.active{
		        &:hover{
		            figure{
		                &:before{opacity: 1; visibility: visible;}
		            }
		            .desc{opacity: 1; visibility: visible;}
		        }
	        }
		}
	}
	.box-lsdokter{
		.lsdokter{
			margin-bottom: 40px;
			h3{font-size: 24px;}
			.img{width: 42%;}
			.desc{width: 52%; padding-top: 20px;}
		}
	}
	.bysidebtn{
		.left, .right{width: 100%; float: left; display: block; margin-bottom: 15px; text-align: left;}
	}
	.detaildokter{
		h3{font-size: 23px;}
		h6{font-size: 16px;}
		p{font-size: 16px; margin-bottom: 10px;}
		> .left{
			width: 40%;
			figure{margin-bottom: 30px;}
		}
		> .right{width: 55%;}
	}
	.box-roundedgrey{
		.ttlroundedgrey{padding: 20px; font-size: 18px;}
		.descroundedgrey{padding: 20px 20px;}
	}
	.promolg{
		h3{font-size: 26px; margin-bottom: 0;}
		.left{
			width: 100%;
			figure{
				margin-bottom: 0;
				img{width: 100%;}
			}
		}
		.right{width: 100%;}
	}
	.promolist{
		.ls-promolist{
			width: 50%; margin-bottom: 30px;
			figure{
				img{width: 100%;}
			}

			&:nth-child(3n+1){clear: inherit;}
			&:nth-child(2n+1){clear: both;}
			&:nth-child(3n+2){
				padding-top: 0;
	            &:before{top: 0;}
	            figure{
	                order: 1;
	                &:before{bottom: -16px; left: 0; right: auto; top: auto;}
	                img{@include borderRadius(10px 10px 0 0);}
	            }
	            .desc{order: 2;@include borderRadius(0 0 10px 10px); padding-bottom: 30px; padding-top: 42px;}
			}
			&:nth-child(even){
	            &:before{top: 45px;}
	            figure{
	                order: 2;
	                &:before{bottom: auto; left: auto; right: 0; top: -16px;}
	                img{@include borderRadius(0 0 10px 10px);}
	            }
	            .desc{order: 1;@include borderRadius(10px 10px 0 0); padding-top: 30px; padding-bottom: 42px;}
	        }
		}
	}
	.box-bysidecontent{
		margin-bottom: 20px;
		h2{font-size: 25px; height: auto; max-height: 95px; margin-bottom: 10px;}
		.left{width: 48%;}
		.right{width: 48%;}
		&.margtop{margin-top: 75px;}
	}
	.mapcontact{margin-bottom: -50px;}
	.frm-side{
		.right{bottom: -120px; width: 57%; right: -8%;}
	}


	.box-address{
		.lf, .rg{width: 100%;}
	}

	table{
		&.table-doctor{
			th, td{
				padding: 10px 15px;
			}
			thead{
				th, td{padding: 15px; font-size: 14px;}
			}
		}
	}

	.box-schedule{
	    .ls-schedule{
	    	@include afterboth;
	        .lf, .rg{width: 100%;}
	        .rg{text-align: left;}
	    }
	}


	.grid-accordion{
		.ga-panel{
			img{width: 100%;}
			h4{margin-bottom: 0 !important; height: 62px; overflow: hidden;}
			> a{
				position: absolute; top: 0; left: 0; right: 0; bottom: 0;
				img{max-width: none !important; height: 100% !important; object-fit: cover; object-position: top left;}
			}
		}
		/*.ga-layer{
			&.ga-padding{padding-left: 15px; padding-right: 15px;}
		}*/
	}

	footer{
		.foot-top{
			.left, .right{padding: 40px;}
		}
		.foot-bottom{
			ul{padding-top: 5px;}
			.logo-foot{
				img{width: 180px;}
			}
			.centerfoot{width: 40%;}
			.box-inquiry{
				.btn-rounded{text-align: center; padding-left: 10px; padding-right: 10px;}
			}
			.box-sosmed{
				text-align: right;
				label{display: none;}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 730px) {
	.btn-tab{
		@include afterboth;
		.lsbtn-tab{
			width: 50%; margin-right: 0; @include boxSizing(border-box); padding-left: 20px; padding-right: 20px; display: block; float: left; font-size: 14px;
			span{display: block; margin-top: 20px;}
		}
		&.lg{
			.lsbtn-tab{font-size: 16px;}
		}
	}
}